
import {
    defineComponent,
    PropType,
    reactive,
    ref,
    Ref,
    computed,
    watch
} from 'vue';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import HttpRequest from '@/util/axios.config';
import { phoneCode, timeZone, systemConfig } from '@/data';
import { getPhoneCodeOps } from '@/util/phone-code';
import { isCNServer } from '@/util/location';
import { user, device } from '@/methods/rule';
import noticeBase from '@/util/notice.base';
import relay from '@/components/view/common/relay';
import { userForSingleApi } from '@/api';
import { FormData } from './all.type';

export default defineComponent({
    components: {
        dialogShell,
        relay
    },
    props: {
        operaType: {
            type: String as PropType<OperateType>,
            required: true
        },
        initForm: {
            type: Object as PropType<FormData>,
            required: true
        }
    },
    setup(props, { emit }) {
        const { timeZoneOptions } = timeZone;
        const isDisMonitor = computed(() => props.initForm.isDisMonitor);
        const rules = {
            Name: [{
                required: true,
                message: WordList.RuleNameEmpty,
                trigger: 'blur'
            }, {
                validator: user.checkManageAccount,
                trigger: 'blur'
            }],
            Email: [{
                required: true,
                message: WordList.RuleEmailEmpty,
                trigger: 'blur'
            },
            {
                validator: user.checkEmail,
                trigger: 'blur'
            }],
            MobileNumber: [{
                required: true,
                message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextMobileNumber),
                trigger: 'blur'
            }],
            Address: [{
                validator: user.checkAddress,
                trigger: 'blur'
            }],
            MAC: [{
                required: true,
                message: WordList.RuleMacEmpty,
                trigger: 'blur'
            }, {
                validator: device.checkMAC,
                trigger: 'blur'
            }],
            Location: [{
                required: true,
                message: WordList.RuleLocationEmpty,
                trigger: 'blur'
            }],
            IsPremiumPlanStatus: [{
                required: true,
                message: WordList.RuleCommonLabelEmpty2.format(WordList.PremiumPlan),
                trigger: 'blur'
            }],
            PhoneStatus: [{
                required: true,
                message: '',
                trigger: 'blur'
            }]
        };

        const titles = {
            add: WordList.TabelUserTitleAdd,
            edit: WordList.TabelPersonUserTitleModify
        };
        const api = {
            add: userForSingleApi.addMainUser,
            edit: userForSingleApi.editMainUser
        };
        const formRef: Ref<any> = ref(null);

        const formData = reactive({ ...props.initForm });
        const isDisPremiumPlan = computed(() => formData.EnableSmartHome.toString() === '1');

        const initShowMonitor = props.initForm.isSingleMonitor === '1';
        // 判断是否显示绑定设备选项（super关闭且住户本身删除绑定设备才隐藏）
        const showIndoorMonitor = ref(false);
        watch(isDisMonitor, () => {
            showIndoorMonitor.value = !(isDisMonitor.value === '0' && formData.isSingleMonitor === '0');
        });

        formData.Email = formData.Email || '';
        if (formData.Email === '') rules.Email = [{ validator: user.checkEmail, trigger: 'blur' }];
        formData.MobileNumber = formData.MobileNumber || '';
        if (formData.MobileNumber === '') rules.MobileNumber = [];

        const submit = (relayValue?: string) => {
            // relay组件存在保存传出relay值
            if (relayValue) {
                formData.Relay = relayValue;
            }
            formRef.value.validate((valid: boolean) => {
                if (!valid) return false;
                // 临时方案：后端暂不改IsPremiumPlanStatus传值，只展示
                formData.PremiumPlan = formData.IsPremiumPlanStatus;
                api[props.operaType](formData, () => {
                    emit('success');
                    emit('close');
                });
                return true;
            });
        };

        const resetPw = () => {
            HttpRequest.post('personalresetpw', { ID: formData.ID }, [(data: { msg: string }) => {
                noticeBase.messageBox('alert', data.msg, ' ', 'success')(() => false);
            }, false]);
        };

        const commit = ref(0);
        const checkValid = () => {
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    // relay隐藏时
                    if (formData.isSingleMonitor === '0'
                        || formData.EnableSmartHome.toString() === '1') {
                        submit();
                    } else {
                        commit.value += 1;
                    }
                }
            });
        };

        const limitMobileNumberType = (val: string) => {
            const reg = /[^\d]]*/g;
            formData.MobileNumber = val.replace(reg, '');
        };

        // tag6.4-smarthome 需求
        const smartHomeDisabled = formData.EnableSmartHome.toString() === '1' && props.operaType === 'edit';

        const initMac = formData.MAC;
        const isShowDevice = ref(false);
        // const isShowDevice = !(props.operaType === 'edit' && formData.EnableSmartHome.toString() === '1' && !formData.MAC);

        watch(() => formData.EnableSmartHome, (val) => {
            if (props.operaType === 'add') {
                if (val.toString() === '1') {
                    isShowDevice.value = false;
                    formData.IsPremiumPlanStatus = '0';
                } else {
                    isShowDevice.value = true;
                }
            } else if (val.toString() === '1') {
                isShowDevice.value = !!initMac;
            } else {
                isShowDevice.value = true;
            }
        }, {
            immediate: true
        });

        const changePremiumPlan = (premiumPlan: number) => {
            if (
                props.operaType === 'edit'
                && premiumPlan === 0
                && formData.IsHasThirdPartyCameras === 1
            ) {
                noticeBase.messageBox(
                    'confirm',
                    WordList.PremiumPlanChangeNotice,
                    WordList.Notice,
                    'warning'
                )(() => {
                    formData.IsPremiumPlanStatus = '0';
                }, () => {
                    formData.IsPremiumPlanStatus = '1';
                });
            }
        };

        // 重置room
        function resetRoom() {
            noticeBase.messageBox(
                'confirm',
                WordList.ResetTips,
                WordList.TabelDeleteTitle,
                'warning'
            )(() => {
                userForSingleApi.resetRoom({
                    ID: formData.ID
                }, () => {
                    emit('success');
                    emit('close');
                });
            });
        }

        return {
            titles,
            formData,
            langOptions,
            rules,
            timeZoneOptions,
            phoneCode,
            isCNServer: isCNServer(),
            formRef,
            submit,
            resetPw,
            isDisMonitor,
            commit,
            checkValid,
            showIndoorMonitor,
            initShowMonitor,
            limitMobileNumberType,
            sysConfig: systemConfig.sysConfig,
            smartHomeDisabled,
            isShowDevice,
            isDisPremiumPlan,
            changePremiumPlan,
            resetRoom,
            getPhoneCodeOps
        };
    }
});
